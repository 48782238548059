import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '~/components/seo'
import styled from '@emotion/styled'
import { Col, ColText, TwoColContainer } from '../utils/styles'

const AboutPage = ({ data }) => (
  <>
    <SEO title="About" keywords={[`gatsby`, `application`, `react`]} />
    <TwoColContainer>
      <Col>
        <Img fluid={data.file.childImageSharp.fluid} />
      </Col>
      <ColText>
        <h2>About</h2>
        <p>
          I have loved flower paintings from the day my father painted one and
          gave it to me for my eighth birthday. Since then I have been unable to
          resist buying them in auctions, markets and antique shops building up
          a collection of much loved examples in my home. As my walls heaved
          with paintings, I decided it was time to make something more of this
          passion and now collect and sell them - hanging them in my home until
          they find a loving owner.
        </p>

        <p>
          I work in Interior Design and use these paintings frequently in my
          work. I love co-ordinating colours but good styling always has a
          surprise element and these paintings provide endless inspiration for
          decorating, invariably mixing colours in an interesting way. Nature
          rarely finds the need to match...
        </p>
        <p>
          Some paintings are of a better quality than others but all hold a
          special charm. I find that while the best ones stand alone, groups of
          them provide a riot of colourful impact and if one or two are not
          perfect they can still add interest to a group.
        </p>
        <p>
          All these paintings are vintage – generally early twentieth to the
          mid/later twentieth century and as such can show signs of wear and
          tear including the original frames which I occasionally repaint and
          restore if needed. I also reframe selected paintings and hand paint
          them with ornamental patterns and Bloomsbury inspired designs.
        </p>
        <p>Please email me if you have any queries</p>
      </ColText>
    </TwoColContainer>
  </>
)

export const pageQuery = graphql`
  {
    file(name: { eq: "about-picture" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default AboutPage
